import { conformToMask } from 'text-mask-core'
import createNumberMask from 'text-mask-addons/dist/createNumberMask'
import { AsYouType, getExampleNumber } from 'libphonenumber-js'
import examples from 'libphonenumber-js/mobile/examples'
import sanitizeHtml from 'sanitize-html'

let localContext
export const utils = {
  // Strings to check for capitalisation on
  toCapitalise: ['SMSF', 'SAF', 'TTR', 'DVA', 'TPD', 'HECS HELP', 'HECS/HELP', 'FEE-HELP', 'SA-HELP', 'OS-HELP', 'TFN', 'FBT'],
  lowercase: ['And', 'Of', 'Or', 'To', 'At'],

  checkUpper(text) {
    return text && utils.toCapitalise.includes(text.toUpperCase()) ? text.toUpperCase() : _.startCase(_.camelCase(text))
  },
  concatStrings: (separator, ...strings) => {
    // Takes any number of strings or arrays to concat
    let concat = ''
    strings.forEach((string) => {
      if (!Array.isArray(string)) string = [string]
      string.forEach((el) => {
        if (el && typeof el === 'string' && el.trim() !== '') {
          concat += el + separator
        }
      })
    })
    // Remove separator from end of string
    if (concat.length > separator.length) {
      concat = concat.slice(0, -separator.length)
    }
    return concat
  },
  themeSwapper(lightTheme, darkTheme) {
    //We only support light theme
    return lightTheme
  },
  keyValueMapper(array, key, value, keyName, valueName) {
    //return empty if not an array
    if (!Array.isArray(array)) return []

    //Set Defaults
    if (!key) key = 'text'
    if (!value) value = 'value'
    return array.map((el) => {
      let obj = {}
      obj[key] = _.get(el, keyName, el)
      obj[value] = _.get(el, valueName, el)
      return obj
    })
  },
  initals(fullname) {
    let initals = ''
    if (fullname) {
      fullname.split(' ').forEach((word) => {
        initals += word.charAt(0).toUpperCase()
      })
    }
    return initals
  },
  addressConcat: (address) => {
    return utils.concatStrings(', ', address.addressOne, address.addressTwo, address.suburb, address.state, address.postCode)
  },
  getArrayOfDayFilters() {
    let dates = ['Today']
    let addDays = 0
    // [today, tomorrow, wed, thur, fri]
    // this is our maximum case hense the
    for (let i = 0; i <= 3; i++) {
      addDays++
      if (localContext.$moment().add(addDays, 'day').day() === 6 || localContext.$moment().add(addDays, 'day').day() === 0) {
        break
      }
      if (addDays === 1) {
        dates.push('Tomorrow')
      } else {
        dates.push(localContext.$moment().add(addDays, 'day').format('dddd'))
      }
    }
    return localContext.$utils.keyValueMapper(dates.concat(['Next 7 Days', 'Future']))
  },
  dateFormatter(date) {
    let now = localContext.$moment()
    let momentDate = localContext.$moment(date)
    if (now.week() === momentDate.week()) {
      if (now.day() === momentDate.day()) return 'Today'
      if (localContext.$moment(now).add(1, 'day').day() === momentDate.day()) return 'Tomorrow'
      return momentDate.format('dddd')
    }
    return 'Future'
  },
  capitaliseWords: (words, uppercase = [], ignoreLowercase = false) => {
    if (!words) return
    let isArray = Array.isArray(words)
    let capitalised = []
    if (_.isNil(words) || (!isArray && _.isObject(words))) return null
    if (isArray) {
      words.forEach((el) => {
        // if the word is like WILL & DANI Keep the &
        // else remove all symbols so "TYPE_A" becomes Type A
        if (el.includes('&') || el.includes('/')) {
          capitalised.push(el.replace(/\w+/g, _.capitalize))
        } else {
          capitalised.push(_.startCase(_.camelCase(el)))
        }
      })
    } else {
      if (words.includes('&') || words.includes('/')) {
        capitalised.push(words.replace(/\w+/g, _.capitalize))
      } else {
        capitalised.push(_.startCase(_.camelCase(words)))
      }
    }

    let toUpper = Array.isArray(uppercase) ? uppercase.concat(utils.toCapitalise) : [uppercase].concat(utils.toCapitalise)
    for (let i = 0; i < capitalised.length; i++) {
      // Convert certain words to lowercase
      if (!ignoreLowercase) {
        utils.lowercase.forEach((lower) => {
          const rgx1 = new RegExp(`[?!^ ]${lower}[ ]`)
          // above: only replace 'or'/'and' etc if they are surrounded by a space and aren't at the start of the string
          capitalised[i] = capitalised[i].replace(rgx1, ' ' + lower.toLowerCase() + ' ')
        })
      }

      if (toUpper.length > 0) {
        // Uppercase certain words if required
        let foundIndex = toUpper.findIndex((el) => capitalised[i].toLowerCase().includes(el.toLowerCase())) // If the word contains a string in the uppercase array, turn it into uppercase
        if (foundIndex >= 0) {
          // Split the input text into words
          const words = capitalised[i].split(' ')

          // Capitalize specific words
          const capitalizedWords = words.map((word) => {
            const uppercaseWord = word.toUpperCase()
            if (utils.toCapitalise.includes(uppercaseWord)) {
              return uppercaseWord
            }
            return word
          })

          capitalised[i] = capitalizedWords.join(' ')
        }
      }
    }
    return isArray ? capitalised : capitalised[0]
  },
  validDate(date, isMonth = false) {
    // Format date as ISO
    if (!date) return false
    if (isMonth) {
      const [month, year] = date.split('/').length === 2 ? date.split('/') : date.split('/').splice(1)
      if (month !== undefined && year !== undefined) {
        date = `${year}-${month.padStart(2, '0')}-01`
      }
    } else {
      const [day, month, year] = date.split('/')
      if (day !== undefined && month !== undefined && year !== undefined) {
        date = `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
      }
    }

    date = utils.dateToIso(date, isMonth)
    if (utils.isIso(date) && localContext.$moment(date).isValid()) {
      return true
    }
    return false
  },
  isIso(date) {
    if (/^\d{4}-\d{1,2}-\d{1,2}$/.test(date) || /^\d{4}-\d{1,2}$/.test(date)) {
      return true
    }
    return false
  },
  dateToIso: (date, month = false) => {
    if (!date || date === undefined) {
      return ''
    }
    if (/^\d{4}$/.test(date)) return date + '-01-01' // Convert year to ISO date
    if (date instanceof Date) date = date.toISOString()
    if (utils.isIso(date)) {
      // Was ISO format already
      if (/^\d{4}-\d{1,2}$/.test(date) && !month) {
        // In month format but month no month parameter, append day to the ISO date
        return date + '-01'
      }
      return date
    } else if (!month && /^\d{4}-\d{1,2}-\d{1,2}/.test(date)) {
      // Is ISO format but has extra characters
      return date.replace(/(\d{4}-\d{1,2}-\d{1,2}).*/, '$1')
    } else if (month && /^\d{4}-\d{1,2}/.test(date)) {
      return date.replace(/(\d{4}-\d{1,2}).*/, '$1')
    }

    // Check if the date is in short or long format, convert either to ISO format
    try {
      if (!month && /^\d{1,2}\/\d{1,2}\/\d{4}$/.test(date)) {
        // Passing full date
        const [day, month, year] = date.split('/')
        return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
      } else if (localContext.$moment(date, 'Do MMM[,] YYYY').format('Do MMM[,] YYYY') === date) {
        return localContext.$moment(date, 'Do MMM[,] YYYY').format('YYYY-MM-DD')
      } else if (month && (/^\d{1,2}\/\d{4}$/.test(date) || /^\d{1,2}\/\d{1,2}\/\d{4}$/.test(date))) {
        // Passing month/year
        const [month, year] = date.split('/').length === 2 ? date.split('/') : date.split('/').splice(1)
        return `${year}-${month.padStart(2, '0')}`
      } else if (localContext.$moment(date, 'MMM[,] YYYY').format('MMM[,] YYYY') === date) {
        return localContext.$moment(date, 'MMM[,] YYYY').format('YYYY-MM')
      }
    } catch (e) {
      console.log('Error passing date: ' + e)
    }
    return ''
  },
  formatDate: (date, toShortFormat = false) => {
    if (!date || date === undefined) {
      return ''
    }
    date = utils.dateToIso(date)
    if (date) {
      let formatted = toShortFormat ? localContext.$moment(date).format('DD[/]MM[/]YYYY') : localContext.$moment(date).format('Do MMM[,] YYYY')
      return formatted !== 'Invalid date' ? formatted : ''
    }
    return ''
  },
  formatMonth: (date, toShortFormat = false) => {
    if (!date || date === undefined) {
      return ''
    }
    date = utils.dateToIso(date, true)
    if (date) {
      let formatted = toShortFormat ? localContext.$moment(date).format('MM[/]YYYY') : localContext.$moment(date).format('MMM[,] YYYY')
      return formatted !== 'Invalid date' ? formatted : ''
    }
    return ''
  },
  formatDateRange: (date, month = false) => {
    if (!date || !date.start) return ''
    const formatter = month ? utils.formatMonth : utils.formatDate
    // Check if one off
    if (date.oneOff) return formatter(date.start) + ' (One-off)'
    // Check if no end
    if (!date.end) return formatter(date.start) + ' - Ongoing'
    // Check if end actually comes after start
    return formatter(date.start) + ' - ' + formatter(date.end)
  },
  /**
   * @param {*} number
   * @returns THE PHONE NUMBER!
   */
  formatPhone: (number, countryCode = 'AU') => {
    if (!number && number !== '0') return ''

    number = String(number)
    const inputVal = number.replace(/\s/g, '')

    const asYouType = new AsYouType(countryCode)
    // As you type formatting
    asYouType.input(inputVal)

    return (asYouType.getNumber() && asYouType.getNumber().formatInternational()) || ''
  },
  isPhoneValid(number) {
    if (!number) return { valid: true, example: '' }
    const asYouType = new AsYouType()
    asYouType.input(number)

    if (asYouType.isValid()) return { valid: true, example: '' }

    const phoneNumber = getExampleNumber(asYouType.getNumber()?.country, examples)

    return { valid: false, example: phoneNumber && phoneNumber.formatInternational() }
  },
  formatPlace: (address, hideCountry = false) => {
    if (!address) return ''
    if (address.country === 'Australia') hideCountry = true

    return utils.concatStrings(', ', address.addressOne, address.addressTwo, (address.suburb ? address.suburb : '') + ' ' + (address.state ? address.state : ''), address.postCode, hideCountry ? '' : address.country)
  },
  /* One number format function to rule them all */
  formatNumber(number, numType = 'digit', noCommas = false, negative = false) {
    if (!number && number !== 0) return number

    number = this.stripNumber(number, negative)

    let mask = null
    switch (numType) {
      case 'digit':
        mask = {
          prefix: '',
          suffix: '',
          includeThousandsSeparator: !noCommas,
          allowNegative: true,
          allowDecimal: false,
        }
        break
      case 'number':
        mask = {
          prefix: '',
          suffix: '',
          includeThousandsSeparator: !noCommas,
          allowNegative: true,
          allowDecimal: true,
        }
        break
      case 'money':
      case 'money-prefix':
      case 'percent':
      case 'percent-suffix':
        mask = {
          // Formatting in tables
          prefix: numType === 'money-prefix' ? '$' : '',
          suffix: numType === 'percent-suffix' ? '%' : '',
          includeThousandsSeparator: !noCommas,
          allowNegative: true,
          allowDecimal: true,
          decimalLimit: numType === 'money' ? 2 : 4,
        }
        break
      case 'bsb':
        mask = [/\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/]
        break
      case 'abn':
        mask = [/\d/, /\d/, ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/]
        break
      case 'tfn':
        mask = [/\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/]
        break
      default:
        mask = ''
        break
    }

    let _mask = []
    if (Array.isArray(mask)) {
      _mask = mask
    } else if (typeof mask === 'object') {
      _mask = createNumberMask(mask)(number)
      if (Array.isArray(_mask)) _mask = _mask.filter((v) => v !== '[]')
    }

    const result = conformToMask(number, _mask, { guide: false })
    if (result.conformedValue) return result.conformedValue
    else return number
  },
  /**
   * Removes special characters from a number. Will also format as non-scientific notation if the number is too large.
   * The JS Number type will only store a maximum of 16 significant figures, and the rest is lost but still formatted as trailing 0's
   */
  stripNumber(number, negative = false) {
    if (number === null || number === undefined) return String('')
    if (typeof number === 'number') {
      if (number > Number.MAX_SAFE_INTEGER) {
        number = number.toLocaleString()
      }
    }
    let num = String(number)
    if (negative && num.charAt(0) !== '-') num = '-' + num // Make the number negative if it needs to be

    return (
      num
        // Replace any commas, underscores, percentages or dollar symbols
        .replace(/[,_%$]/g, '')
        // Only replace dashes if they are NOT at the beginning of the string (to allow negatives)
        .replace(/(?<!^)-/g, '')
        .trim()
    )
  },
  getFormData: (form) => {
    const data = {}
    for (let index = 0; index < form.inputs.length; index++) {
      // Get name of element
      let name = form.inputs[index].$options.parent.$attrs.name
      if (name === undefined) {
        // If the element is undefined, check if there is an element inside the element
        // Have to use 'componentName' prop instead of name for date data fields
        name = form.inputs[index].$options.parent.$options.parent.$attrs.name
      }
      // Try to convert to ISO date to determine if the field is a date value
      let value = utils.dateToIso(form.inputs[index].lazyValue) !== null ? utils.dateToIso(form.inputs[index].lazyValue) : form.inputs[index].lazyValue

      if (name !== undefined) {
        data[name] = value
      }
    }
    return data
  },
  sortByKeys: (object, lastOverride, manualSort) => {
    const keys = Object.keys(object)
    let sortedKeys = _.sortBy(keys)
    if (manualSort) sortedKeys = _.intersection(manualSort, sortedKeys)
    else if (lastOverride) {
      sortedKeys.push(sortedKeys.splice(sortedKeys.indexOf(lastOverride), 1)[0])
    }
    return _.fromPairs(_.map(sortedKeys, (key) => [key, object[key]]))
  },
  isObject(item) {
    return typeof item === 'object' && item !== null && !Array.isArray(item)
  },
  hasVal(item) {
    return item !== undefined && item !== null
  },
  addCommas: (num, decimal = true) => {
    if (num || num == 0) {
      let numString = num.toString()
      let negative = false

      if (numString.trim().charAt(0) === '-') negative = true

      if (decimal) {
        numString = numString.replace(/[^0-9.]/g, '').replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')
      } else {
        numString = numString.replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      }

      if (negative) numString = '-' + numString

      return numString
    }
    return ''
  },
  getBackgroundColor(stringInput) {
    let stringUniqueHash = [...stringInput].reduce((acc, char) => {
      return char.charCodeAt(0) + ((acc << 5) - acc)
    }, 0)
    return `hsla(${~~(stringUniqueHash % 360)},28%,50%,1)`
  },
  removeCommas: (num, decimal = true) => {
    if (num) {
      let numString = num.toString()
      let negative = false

      if (numString.includes('-')) {
        negative = true
        numString = numString.substring(numString.indexOf('-'), numString.length)
      }

      if (decimal) {
        numString = numString.replace(/[^0-9.]/g, '').replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, '')
      } else {
        numString = numString.replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, '')
      }

      if (negative) numString = '-' + numString
      return numString
    }
    return 0
  },
  /**
   * When num is null or undefined it will display as '--'
   * @param {*} num
   * @param {*} decimals
   * @param {*} avoidZeroDollarAmount boolean or array. If the array is empty, or the boolean is true. it will return '--'
   * @returns
   */
  moneyDisplay: (num, decimals = 2, avoidZeroDollarAmount = false) => {
    if (num === null || num === undefined) return '--'
    num = utils.removeCommas(num)

    if ((typeof avoidZeroDollarAmount === 'boolean' && avoidZeroDollarAmount === true) || (Array.isArray(avoidZeroDollarAmount) && avoidZeroDollarAmount.length === 0)) {
      if (num === 0 || num === '0' || num === '$0') {
        return '--'
      }
    }

    let negative = false
    if (decimals == 0) num = _.round(Number(num), 0)
    else num = parseFloat(num).toFixed(decimals)
    num = String(num)
    if (num.includes('-')) {
      num = num.substring(num.indexOf('-') + 1, num.length)
      negative = true
    }
    if (num === '0') return '$0'
    return utils.formatNumber(num, 'money-prefix', false, negative)
  },
  percentOf(percentage, amount, decimals = 2) {
    if (!amount || !percentage) return 0
    return _.round((Number(percentage) / 100) * Number(amount), decimals)
  },
  percentDisplay(num, decimals = 2, trailingZeroes = false, valueOnly = false) {
    let rounded = _.round(num, decimals)
    if (!rounded) return valueOnly ? '' : '--'

    if (trailingZeroes) {
      rounded = rounded.toString()
      // Regex to check if decimals are missing
      const regex = new RegExp(`^-?(\\d+(\\.\\d{1,${decimals}})?)?$`)
      const match = regex.exec(rounded)
      if (match) {
        let missingDecimalPlaces = 0
        if (match[2]) {
          // Determine how many decimal places are missing
          const existingDecimalPlaces = match[2].length - 1
          missingDecimalPlaces = decimals - existingDecimalPlaces
          if (missingDecimalPlaces < 0) missingDecimalPlaces = 0
        } else {
          // Missing all decimals, add '.' as well
          missingDecimalPlaces = decimals
          rounded += '.'
        }

        if (missingDecimalPlaces > 0) {
          for (let i = 0; i < missingDecimalPlaces; i++) {
            rounded += '0'
          }
        }
      }
    }
    return rounded + (valueOnly ? '' : '%')
  },
  getPercentage(valueOne, valueTwo) {
    if (!valueOne || !valueTwo) return 0
    return _.round((Number(valueOne) / Number(valueTwo)) * 100, 2)
  },
  addBrackets(value, condition = true) {
    if (condition && value && value !== '--') return `(${value})`
    return value
  },
  /*
    Return null if array items' values are empty (i.e. no information has been added yet)
    Return a number otherwise
  */
  sumArrayObjs: (arr, key, key2 = null) => {
    if (!Array.isArray(arr)) return null

    let invalidIndices = []
    arr.forEach((val, idx) => {
      let v = key2 === null ? val[key] : val[key][key2]
      if (v === '' || v === null || v === undefined) invalidIndices.push(idx)
    })
    if (invalidIndices.length === arr.length) return null

    return arr.reduce((a, b) => a + (Number(key2 ? b[key][key2] : b[key]) || 0), 0)
  },
  objFromValue(arr, value, itemValue = null) {
    let key = itemValue ? itemValue : 'value'
    if ((arr && Array.isArray(arr) && arr.length > 0 && value) || value == 0) {
      return arr.find((item) => item[key] === value || (value.value && item[key] === value.value))
    }
    return false
  },
  nameFromValue: (arr, value, itemValue = null) => {
    let obj = utils.objFromValue(arr, value, itemValue)
    if (obj) return obj.name
  },
  totalPortfolioValue: (portfolio) => {
    let total = 0
    for (const [key, value] of Object.entries(portfolio)) {
      if (value.data && value.data.length > 0) {
        value.data.forEach((item) => {
          if (item.unitPrice > 0 && item.costBase.length > 0) {
            total += Number(item.unitPrice) * Number(utils.sumArrayObjs(item.costBase, 'units'))
          } else if (item.valueInput) {
            total += Number(item.valueInput)
          }
        })
      }
    }
    return total
  },
  storeActionExists: (store, action) => {
    const methodName = store + '/' + action
    const actionExists = Object.keys(localContext.store._actions).findIndex((key) => key === methodName) !== -1
    return actionExists
  },
  isUUID: (val) => {
    if (!val || typeof val !== 'string') return false
    // Test UUID or 24 char ID
    return val.match(/[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}/i) !== null || /^[a-z0-9]{24}$/.test(val) || (typeof val === 'string' && val.substring(0, 5) === 'auth0')
  },
  /**
   * Trims the string to the maximum length and adds ... if the string is larger
   * @param {*} str
   * @param {*} maxLength
   * @returns
   */
  trimString(str, maxLength = 16, omission = '...') {
    if (typeof str !== 'string') return str
    if (typeof maxLength !== 'number') {
      maxLength = parseInt(maxLength)
      if (typeof maxLength !== 'number' || Number.isNaN(maxLength)) return str
    }
    return _.truncate(str, { length: maxLength, omission })
  },
  truncate(str, maxLength = 16, omission = '...') {
    return this.trimString(str, maxLength)
  },
  formatDateForSever(date) {
    let momentDate = localContext.$moment(date, 'DD/MM/yyyy')
    if (!momentDate.isValid()) momentDate = localContext.$moment(date)
    if (!momentDate.isValid()) return null
    return _.cloneDeep(momentDate).format('yyyy-MM-DD')
  },
  getOwnershipString(ownership, jointOverride = false) {
    // Ownership as array or single string
    let owners = null
    if (ownership?.items) owners = ownership.items
    else if (typeof ownership === 'string') owners = [{ name: ownership }]
    if (!owners || !Array.isArray(owners)) return ''

    let ownerships = []
    owners.forEach((el) => {
      let name = utils.isUUID(el.name) ? utils.getDisplayName(el.name) : el.name
      let percentage = el.percentage ? `(${Number(el.percentage).toLocaleString(window.navigator.language, { maximumFractionDigits: 2 })}%)` : ''

      if (name) ownerships.push(`${name} ${percentage}`)
    })

    return ownerships.join(', ')
  },
  // Gets extra text to describe the object
  getSubtitle(id) {
    let idRef = id
    if (typeof id === 'object') {
      localContext.store.commit('application/globalSearch/updateIdCache', [id])
      idRef = _.get(id, 'id') // Convert object to just ID
    }
    if (!idRef || typeof idRef !== 'string') return '' // Return if invalid

    const module = utils.storeFromId(idRef)
    if (!module) return ''
    if (localContext.store.getters[`modules/${module}/getSubtitle`]) {
      // If a getSubtitle getter exists, use it
      return localContext.store.getters[`modules/${module}/getSubtitle`](idRef)
    }
    return ''
  },
  // Gets the name to display from a given ID or object with an ID
  // Uses backupNameKey as a backup name if there is no id prefix
  // ! NOTE: if you get an infinite loop in here, be sure to pass in the id rather than the whole object
  getDisplayName(id, backupNameKey = 'name') {
    const defaultName = 'Unnamed'
    const backupName = _.get(id, backupNameKey, defaultName)
    if (typeof id === 'object') {
      // This shit causes the infinite loop
      localContext.store.commit('application/globalSearch/updateIdCache', [id])
      id = _.get(id, 'id') // Convert object to just ID
    }
    if (!id || typeof id !== 'string') return defaultName // Return if invalid
    if (!utils.isUUID(id) && !id.includes('requests-')) return id // Is a string but not a UUID, might be the name already
    const module = utils.storeFromId(id).trim()
    if (!module) return backupName
    try {
      const name = localContext.store.getters[`modules/${module}/getLabel`](id)
      if (!name) {
        localContext.store.dispatch(`modules/${module}/getAll`) // Try fetch data if it hasn't been fetched so the name can then be displayed (e.g. for ownership labels)
        return defaultName
      }
      return name
    } catch (ex) {
      console.error('Could not find getLabel getter for store ', module, ex)
      return defaultName
    }
  },
  getMultipleDisplayNames(ids) {
    let names = []
    if (!Array.isArray(ids)) return names
    ids.forEach((id) => {
      names.push(utils.getDisplayName(id))
    })
    return names
  },
  // _.get will only return the default value if its null, if it undefined then it will return undefined
  // thus this checks if the path is null Or Undefined and returns th
  getWithDefault(object, path, defaultValue) {
    const result = _.get(object, path, null)
    return _.isNil(result) ? defaultValue : result
  },
  /**
   * @param {String} date1
   * @param {String} date2
   * @param {String} unitOfTime 'years', 'days', 'weeks', 'months', etc
   * @param {Number} decimals Number of digits to show after decimal point
   * @param {Boolean} formatString formats the return value as `4 months` rather than just `4`
   */
  stringDefault(string, defaultTo) {
    if (!string || string.trim() === '') return defaultTo
    return string
  },
  getDurationBetweenDates(date1, date2, unitOfTime = 'years', decimals = 0, formatString = false) {
    if (!date1 || !date2) return null
    date1 = this.dateToIso(date1)
    date2 = this.dateToIso(date2)

    const start = localContext.$moment(date1)
    const end = localContext.$moment(date2)
    const difference = end.diff(start, unitOfTime, unitOfTime === 'years')

    if (Number.isNaN(difference)) return null

    if (Number(difference) < 0) return null

    let num = Number(difference).toFixed(decimals)
    num = num.toString().endsWith('.0') ? num.replace('.0', '') : num

    return formatString ? `${unitOfTime === 'years' ? num : difference} ${num === `1` ? 'year' : unitOfTime}` : num
  },
  initials(fullname) {
    let initials = ''
    let split = fullname.split(' ')
    for (let i = 0; i <= 1; i++) {
      if (split[i] && typeof split[i] === 'string') initials += split[i].charAt(0).toUpperCase()
    }
    return initials
  },
  getRouteParams(route = null, splitter = '/') {
    // Get all current route parameters or from a given route
    let toSplit = route ? route : localContext.route.path
    toSplit = toSplit.charAt(0) === splitter ? toSplit.slice(1) : toSplit
    return _.split(toSplit, splitter)
  },
  getRouteFullPath() {
    return localContext.route.fullPath
  },
  appendOptions(list, newActivities) {
    if (_.isNil(newActivities)) return list
    if (!Array.isArray(newActivities)) newActivities = [newActivities]
    // Convert new activities to start-case camel case
    const formattedActivities = newActivities.map((activity) => _.startCase(_.camelCase(activity)))
    // Append the formatted activities to the original list
    const updatedList = list.concat(formattedActivities.map((name) => ({ name, value: _.upperCase(name) })))

    return _.uniqBy(updatedList, 'value')
  },
  isOverflowing(el) {
    let component = el
    // Check if component content is overflowing
    if (component) {
      if (_.get(component, '[0]')) component = component[0]
      // If ref is for NuxtLink, the child element is the component
      if (component?._name === '<NuxtLink>') {
        component = _.get(component, '$el.children[0]')
      }
      if (component?.clientWidth && component?.scrollWidth) {
        return component.clientWidth < component.scrollWidth
      }
    }
    return false
  },
  /**
   * Provides access to the full list of vuetify colors
   * @param {string} colorKey e.g. '--v-primary-lighten3'
   * @returns hex string
   */
  getComputedColor(colorKey) {
    return getComputedStyle(document.documentElement).getPropertyValue(colorKey).trim()
  },
  dateToNameConvertor(date) {
    if (date === 'noneSet') return 'No Due Date'
    if (date.isAfter(localContext.$moment().add(6, 'days').endOf('day'))) return 'Future'
    if (date.isAfter(localContext.$moment().add(1, 'days').endOf('day'))) return 'Next 7 Days'
    if (date.isAfter(localContext.$moment().endOf('day'))) return 'Tomorrow'
    if (date.isAfter(localContext.$moment().subtract(1, 'days').endOf('day'))) return 'Today'
    return 'Overdue'
  },
  //Dont think this is used now
  nameToDateConvertor(name) {
    if (name.toLowerCase() === 'overdue') return [localContext.$moment().subtract(100, 'years').startOf('day'), localContext.$moment().subtract(1, 'days').endOf('day')]
    if (name.toLowerCase() === 'today') return [localContext.$moment().startOf('day'), localContext.$moment().endOf('day')]
    if (name.toLowerCase() === 'tomorrow') return [localContext.$moment().add(1, 'days').startOf('day'), localContext.$moment().add(1, 'days').endOf('day')]
    if (name.toLowerCase() === 'nextweek') return [localContext.$moment().startOf('day'), localContext.$moment().add(7, 'days').endOf('day')]
    if (name.toLowerCase() === 'future') return [localContext.$moment().add(8, 'days').startOf('day'), localContext.$moment().add(100, 'years').endOf('day')]
  },
  generateCopyName(name, existingNames) {
    if (!Array.isArray(existingNames)) return name + ' (1)'

    let newName = typeof name === 'string' ? name.trim() : name
    let match = newName.match(/\((\d)\)$/)
    let num = 0

    if (match) {
      num = parseInt(match[1]) // Use existing number as the starting number
    } else {
      newName += ' (0)' // 0 gets bumped up to 1
    }

    do {
      num++
      if (newName.includes(`(${num - 1})`)) {
        // Increment the number
        newName = newName.replace(`(${num - 1})`, `(${num})`)
      } else {
        // Default to appending (1) if the number could not be replaced for some reason
        newName += ' (1)'
      }
    } while (existingNames.includes(newName))

    return newName
  },
  storeFromId(id) {
    // Given an ID, return a store name if the ID includes one
    if (!id || typeof id !== 'string') return ''
    let subStr = id.substring(0, id.indexOf('-'))
    if (!/^[a-zA-Z]+$/.test(subStr)) {
      // If it contains non-letter characters like our stores do
      return ''
    }
    return subStr || ''
  },
  objectFromId(id) {
    const store = utils.storeFromId(id)
    if (store) return localContext.store.getters[`modules/${store}/getObject`](id)
    return null
  },
  isObjectDeleted(id) {
    if (!typeof id === 'string') id = id?.id
    const obj = utils.objectFromId(id)
    return _.isNil(obj) || !_.isNil(obj.deleted)
  },
  /**
   *
   * @param {String} enumName - name of the Enum class in the backend
   * @param {Object} nameMap - object where key is the enum and the value will be the name to replace it with
   * @param {Bolean} valueAsName - makes the object value the same as the name (useful for comboboxes)
   * @returns
   */
  mapEnums(enumName, nameMap = null, valueAsName = false) {
    return localContext.store.getters['application/appData/enum'](enumName).map((el) => {
      const name = _.get(nameMap, el, utils.capitaliseWords(el))
      return { name: name, value: valueAsName ? name : el }
    })
  },
  isNil(obj, defaultVal) {
    return !_.isNil(obj) ? (obj !== 'null' ? obj : defaultVal) : defaultVal
  },
  downloadBlob(blob, fileName) {
    try {
      var a = document.createElement('a')
      document.body.appendChild(a)
      a.style = 'display: none'
      let url = window.URL.createObjectURL(blob)
      a.href = url
      a.download = fileName
      a.click()
      window.URL.revokeObjectURL(url)
    } catch (e) {
      console.error('Failed to download blob:', e)
    }
  },
  base64toFile(data, fileName) {
    if (!data) return null
    if (typeof data !== 'string') return null
    var arr = data.split(',')
    let match = arr[0].match(/:(.*?);/)
    let mime = match[1]
    let bstr = atob(arr[arr.length - 1])
    let n = bstr.length
    let u8arr = new Uint8Array(n)
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n)
    }
    return new File([u8arr], fileName, { type: mime })
  },
  /**
   * Sanitizes HTML input
   * @param {String} input the html input
   * @returns sanitized string
   */
  sanitize(input) {
    return sanitizeHtml(input, {
      allowedTags: ['b', 'u', 'i', 'em', 'strong', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'p', 'span', 'div', 'br', 'ul', 'ol', 'li', 'sub', 'code', 'pre', 'sup', 'blockquote', 'img'],
      parseStyleAttributes: true,
      allowedAttributes: {
        '*': ['style', 'class', 'data-*'],
        img: ['src', 'alt', 'title', 'width', 'height'],
      },
      allowedSchemes: ['data', 'https'],
    })
  },
  maskText(text, mask) {
    switch (mask) {
      case 'enums':
        return _.startCase(text.toLowerCase())
      case 'users':
      case 'assignedUsers':
        if (!String(text).startsWith('auth0|')) return text // 'no assignees', or 'myself'
        return $nuxt.$store.getters['auxiliary/user/getUsernameForId'](text)
      case 'entities':
      case 'assignedEntities':
        const entityName = this.getDisplayName(text)
        return entityName != '' ? entityName : 'Not Found'
      case 'entityGroupId':
        return $nuxt.$store.getters['modules/entityGroups/getGroupName'](text) || 'None Set'
      case 'team':
        const team = $nuxt.$store.getters[`modules/configurationHub/getTeams`].find((team) => team.id === text)
        return team ? team.name : 'None Set'
      case 'taskTags':
      case 'tags':
        let tag = $nuxt.$store.getters[`modules/configurationHub/getTag`](text)
        return tag?.name ?? null
      case 'configurationHub':
        let configItem = $nuxt.$store.getters[`modules/configurationHub/idSearch`](text)
        return configItem?.name ?? null
      case 'dueDate':
        return this.capitaliseWords(this.calcDueDateTitle(text))
      case 'status':
      case 'status':
      case 'type':
        return this.capitaliseWords(text)
      default:
        return !!text ? text : 'None Set'
    }
  },
  calcDueDateTitle(date) {
    if (date === 'No Due Date') return 'None Set'
    if (date === 'Today' || date === 'Future' || date === 'Overdue' || date === 'Next Week' || date === 'Rest of Week') return date

    const convertedDate = localContext.$moment(date, 'DD/MM/yyyy')
    if (convertedDate.isSame(localContext.$moment(), 'day')) {
      return 'Today'
    } else if (convertedDate.isSame(localContext.$moment().add(1, 'days'), 'day')) {
      return 'Tomorrow'
    } else if (convertedDate.isBefore(localContext.$moment().add(6, 'days').endOf('day'))) {
      return convertedDate.format('dddd')
    } else if (convertedDate.isSame(localContext.$moment().add(7, 'days'), 'day')) {
      return 'Next ' + convertedDate.format('dddd')
    }
  },
  /**
   * Given an array of object IDs look through stores for the objects; otherwise check the ID cache
   * @param {Array<String>} ids Array of object IDs
   * @returns Array of objects
   */
  getObjectsFromIds(ids) {
    let foundObjects = []
    // Look through each store from the IDs for the object first
    const storeGroups = _.groupBy(ids, utils.storeFromId)
    for (const [store, values] of Object.entries(storeGroups)) {
      // if (store === '') continue
      if (store) {
        const items = localContext.store.getters[`modules/${store}/getObjects`](values)
        if (Array.isArray(items)) {
          foundObjects.push(...items)
          const foundIds = items.map((el) => el.id)
          _.remove(ids, (id) => foundIds.includes(id))
        }
      } else if (Array.isArray(values) && !_.isNil(values.filter((f) => typeof f === 'string' && f.includes('auth0')))) {
        let users = []
        values.forEach((v) => {
          const user = localContext.store.getters['auxiliary/user/getUser'](v)
          if (!_.isNil(user)) {
            users.push({ id: user.user_id, ...user })
          }
        })
        foundObjects.push(...users)
      }
    }

    // If not found in stores, check the ID cache
    foundObjects.push(..._.values(_.pick(localContext.store.state.application.globalSearch.idCache, ids)))
    // Avoid duplicates
    foundObjects = _.uniqBy(foundObjects, 'id')
    return foundObjects.filter((el) => !el?.deleted)
  },
  /**
   * Will create a duplicate name based off of name.
   * E.g:
   * "My Name" -> "My Name (Copy)"
   * "My Name (Copy)" -> "My Name (Copy 2)"
   * @param {String} name name to duplicate
   */
  duplicateName(name) {
    if (typeof name != 'string') return
    const match = name.match(/^(.*?)\s*(\(Copy(?:\s+(\d+))?\))$/)

    if (match) {
      const baseString = match[1]
      const count = match[3] ? parseInt(match[3]) : 1

      const newName = baseString + ' (Copy ' + (count + 1) + ')'
      name = newName
    } else name = name + ' (Copy)'

    return name
  },
  /**
   * Checks an array to see if the object exists, if it does replace it, otherwise push it
   * @param {Array} arr Array to check for object match
   * @param {Object} object Object to try and find
   * @param {Function} matcher Matcher function, defaults to matching on object ID
   * @returns
   */
  replaceOrAdd(arr, object, matcher = null) {
    if (!Array.isArray(arr) || !object) return
    const foundIndex = arr.findIndex((el) => (typeof matcher === 'function' ? matcher(el, object) : el.id === object.id))
    if (foundIndex >= 0) arr.splice(foundIndex, 1, object)
    else arr.push(object)
  },
}

export default (context, inject) => {
  localContext = context
  inject('utils', utils)
}
