import Vue from 'vue'
/**
 * Use commit 'openDialog' with props to bring up dialog
 */
export const state = () => ({
  show: false,
  idsTofetch: [],
  fetching: false,
  refreshCallee: null,
  idCache: {},
  debounceRefresh: _.debounce((dispatch) => {
    dispatch('emitRefresh')
  }, 1500),
  /**
   * Props to be used in the ApplicationSearchDialog, valid props are:
   * select        |   Boolean       | Set to true for single select mode
   * multiple      |   Boolean       | Set to true for multiple select mode
   * callback      |   Function      | Function from the SearchInput component to give the selection to after applying it
   * group         |   Boolean       | Set to true if data to be shown should only be from the selected group
   * modules       |   Array         | Array of strings matching store names of modules to be included in the search
   * types         |   Object        | Object where keys are the modules and the value is an array of types to be shown, e.g. { entities: ['INDIVIDUAL', 'SMSF'] }
   * selected      |   Array/String  | IDs of objects to be selected when the dialog opens
   *               |      or Objects | Array can also be an array of objects with type and object keys e.g. { type: 'entities', object: { name: 'Joe', ...} }
   * returnObject  |   Boolean       | If true objects will be returned instead of IDs from the selection
   * filters       |   Array         | Array of filter objects to show instead of the default filters
   * customFilters |   Array         | Pass in custom filters when opening the dialog (supports field 'is' and array 'isEmpty')
   * excludeIds    |   Array(string) | Array of IDs to not be returned in the search results
   * createUrls    |   Object        | Object where key is the module being selected and the value is a string of the URL to navigate to when clicking create new, e.g. { entities: 'entities/...' }
   * data          |   Array(object) | Give a custom array of data to display [{ module: 'store name', app: 'Actions', content: [{custom objects to display}] }]
   */
  props: {},
})

export const getters = {
  isShowing(state) {
    return state.show
  },
  isFetching: (state) => (ids) => {
    if (!ids) return false
    if (state.fetching && Array.isArray(state.idsTofetch) && state.idsTofetch.length > 0) {
      // Check if the given IDs are being fetched
      return state.idsTofetch.some((fetchIds) => {
        if (Array.isArray(ids)) {
          // If multiple, compare the fetch arrays
          return _.isEqual(ids, fetchIds)
        } else if (Array.isArray(fetchIds)) {
          // If single, check if it's in any of the fetch arrays
          return fetchIds.findIndex((id) => id === ids) >= 0
        }
      })
    }
    return state.fetching
  },
  getCacheObject: (state) => (id) => {
    return _.get(state, `idCache[${id}]`)
  },
  getObjectsFromIds: (state) => (ids) => {
    let objs = _.values(_.pick(state.idCache, ids))
    return Array.isArray(objs) ? objs : []
  },
}

export const mutations = {
  removeCacheIds(state, ids) {
    // Remove IDs from cache so they can be re-fetched
    if (typeof ids === 'string') ids = [ids]
    if (Array.isArray(ids)) {
      ids.forEach((id) => {
        delete state.idCache[id]
      })
    }
  },
  addIdsToFetch(state, ids) {
    state.idsTofetch.push(ids)
  },
  updateIdCache(state, objs) {
    if (!Array.isArray(objs)) return
    objs.forEach((el) => {
      // Use the ID as the key in cache
      let key = _.get(el, 'id')
      // Set key/val in cache
      if (key) {
        // Only set if the key doesn't exist or the object differs to the new one
        const cachedObj = _.get(state, `idCache[${key}]`)
        if (!cachedObj || !_.isEqual(cachedObj, el)) Vue.set(state.idCache, key, el)
      }
    })
  },
  clearIdsToFetch(state) {
    state.idsTofetch = []
    state.debouncedFetch = null
  },
  openDialog(state, props) {
    state.show = true
    state.props = props
  },
  closeDialog(state) {
    state.show = false
    state.props = {}
  },
  setFetching(state, status) {
    state.fetching = status
  },
}

export const actions = {
  async fetchData({ state, commit, rootGetters }, { collections, search, page, groupId, filters }) {
    let query = {}
    let _collections = collections ? collections : _.get(state.props, 'modules')
    _collections = _collections.filter((el) => rootGetters[`application/devContent/isModuleActive`](el))
    if (_collections) query.collections = _collections
    if (search) query.search = search
    if (page) query.page = page
    if (groupId) query.groupId = groupId

    if (filters && filters.length > 0) {
      if (state.props.filters) {
        // If custom filters are given, format them with group as the key and any values associated with the group will be in an array as the value
        query.filters = filters.reduce((acc, { group, value }) => {
          if (!acc[group]) {
            acc[group] = []
          }
          acc[group].push(value)
          return acc
        }, {})
      } else {
        // Default filters (status & type)
        query.filters = { type: filters.filter((el) => el.group !== 'status').map((el) => el.value), status: filters.filter((el) => el.group === 'status').map((el) => el.value) }
      }
    }

    const types = _.get(state.props, 'types')
    if (types) query.types = types

    const customFilters = _.get(state.props, 'customFilters')
    if (customFilters) {
      _.assign(query, {
        customFilters: _.chain(customFilters)
          .keyBy('value') // Create an object with 'value' as the key
          .mapValues((filter) => _.omit(filter, 'value')) // Remove 'value' from each object, leaving the rest as the value
          .value(),
      })
      // _.assign(query, { customFilters: _.chain(customFilters).keyBy('value').mapValues('is').value() })
    }

    const excludeIds = _.get(state.props, 'excludeIds')
    if (excludeIds) _.assign(query, { excludeIds: excludeIds })

    return await this.$axios.post(`/api/v1/search`, query).then((res) => {
      if (!res?.data) return {}
      let flattenedContent = []

      if (!Array.isArray(_collections)) return res.data

      // Add objects to the ID cache
      _collections.forEach((value) => {
        if (res.data[value] && res.data[value].content) {
          flattenedContent.push(..._.flatMap(res.data[value].content))
        }
      })
      commit('updateIdCache', flattenedContent)
      return res.data
    })
  },
  fetchIds({ state, commit, rootGetters }, ids) {
    if (!ids) return
    if (!Array.isArray(ids)) ids = [ids]
    ids = ids.filter((id) => this.$utils.isUUID(id) && !id.includes('auth') && !Object.keys(state.idCache).includes(id)) // Filter out auth0 IDs and cached IDs
    if (ids.length === 0) return

    // Check if the objects for the IDs exist in stores already - if so don't fetch them
    if (!state.debouncedFetch) {
      let foundObjects = []
      const storeGroups = _.groupBy(ids, this.$utils.storeFromId)
      for (const [store, values] of Object.entries(storeGroups)) {
        if (store) {
          const items = rootGetters[`modules/${store}/getObjects`](values)
          if (Array.isArray(items)) {
            foundObjects.push(...items)
            const foundIds = items.map((el) => el.id)
            _.remove(ids, (id) => foundIds.includes(id))
          }
        }
      }

      commit('addIdsToFetch', ids)
      const uniq = _.uniq(state.idsTofetch.flat())
      if (uniq.length === 0) return // Nothing to fetch

      commit('setFetching', true)
      state.debouncedFetch = _.debounce(async () => {
        this.$axios
          .post(`/api/v1/search/ids`, _.uniq(state.idsTofetch.flat()))
          .then((res) => {
            if (res && res.data) {
              // Add objects to the ID cache
              commit('updateIdCache', res.data)

              // Add products to the ID cache
              res.data.forEach((obj) => {
                if (_.get(obj, 'products')) commit('updateIdCache', obj.products)
              })
              return res.data
            }
          })
          .finally(() => {
            commit('clearIdsToFetch')
            commit('setFetching', false)
          })
      }, 300)
    }
    state.debouncedFetch()
  },
  refreshDialogData({ state, dispatch, commit }, endpoint) {
    // Set refreshCallee the first time this dispatch is called, it is then passed on the event bus 'refresh-search-data' so that we know the initial module the webstomp event was from
    // For example this prevents an event for adding an Individual from being an 'estatePlannings' endpoint because after the add entity call it then creates an estate planning object
    if (!state.refreshCallee) commit('application/storeUtils/setItem', { localState: state, item: 'refreshCallee', value: endpoint }, { root: true })
    // Debounce to avoid refreshing multiple times
    state.debounceRefresh(dispatch)
  },
  emitRefresh({ state, commit }, endpoint) {
    // Need to emit the event here because 'this' can't be accessed in the state from the debounce
    this.$bus.$emit('refresh-search-data', state.refreshCallee)
    commit('application/storeUtils/setItem', { localState: state, item: 'refreshCallee', value: null }, { root: true }) // Reset callee
  },
}
