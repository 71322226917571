import FilterStore from './common/filterStore'

const crud = new FilterStore('requests', null, false, true)

export const state = () => ({
  ...crud.state,
  staleTemplates: true,
  noSidebar: true,
  // axios cancel token
  cancelToken: null,
  toDownload: null,
  topBarButtons: [
    { name: 'Requests', link: '/requests' },
    { name: 'Template Requests', link: '/requests/templates' },
    { name: 'Sections', link: '/requests/sections' },
  ],
  // favourite sections
  sections: [],
})

export const getters = {
  ...crud.getters,
  getLabel: (state, getters) => (id) => {
    const defaultName = 'Unnamed'
    const obj = typeof id === 'object' ? id : getters.getObject(id)
    if (!obj) return defaultName
    return obj?.name
  },
  getSubtitle: (state, getters, rootState, rootGetters) => (id, truncateOptions = { length: Number.MAX_VALUE }) => {
    const obj = getters.getObject(id)
    if (!obj) return ''

    const truncOpts = { length: Number.MAX_VALUE, omission: '...', separator: undefined }
    Object.assign(truncOpts, typeof truncateOptions == 'number' ? { length: truncateOptions } : truncateOptions)

    const clientGroup = $nuxt.$utils.getDisplayName(obj.clientGroupId)
    const entitiesString = !obj?.entities?.length
      ? undefined
      : obj.entities
          .map((el) => $nuxt.$utils.getDisplayName(el))
          .filter((el) => el != 'Unnamed')
          .join(', ')
    return _.truncate([clientGroup, entitiesString].filter((el) => el != undefined).join(' - '), truncOpts)
  },
  getTemplateRequests(state, getters, rootState) {
    return getters['getAllItems'].filter((el) => (!el.deleted || el.deleted === 'ARCHIVED') && el.isTemplate)
  },
  getSections(state) {
    return _.cloneDeep(state.sections) || []
  },
}

export const mutations = {
  ...crud.mutations,
  setToDownload: (state, data) => {
    state.toDownload = data
  },
  setSections(state, payload) {
    state.sections = payload
  },
  addSection(state, payload) {
    state.sections.push(payload)
  },
  removeSection(state, payload) {
    const idx = state.sections.findIndex((el) => el.id == payload.id)
    state.sections.splice(idx, 1)
  },
  updateSection(state, payload) {
    const idx = state.sections.findIndex((el) => el.id == payload.id)
    state.sections.splice(idx, 1, payload)
  },
}
export const actions = {
  ...crud.actions,
  async changeStatus({ commit }, { id, status }) {
    try {
      const res = await this.$axios.patch(`/api/v1/requests/modifyStatus/${id}?status=${status}`)
      if (res.status != 200) return
      commit('updateObject', res.data)
      this.$bus.$emit('request-status', { id: id, status: status }) // Emit event so if the request is opened it is still updated
    } catch (e) {}
  },
  async favouriteSection({ commit }, section) {
    try {
      const { data, status } = await this.$axios.post('/api/v1/requests/section', section)
      if (status === 201) {
        commit('application/snack/set', { type: 'success', message: 'Saved section named ' + data?.title }, { root: true })
        commit('addSection', data)
      } else commit('application/snack/set', { type: 'error', message: data }, { root: true })
    } catch (e) {}
  },
  async fetchSections({ commit, state }) {
    if (state.sections?.length) return state.sections
    const result = await this.$axios.$get('/api/v1/requests/section')
    commit('setSections', result)
    return result
  },
  async importSection({ commit }, { requestId, section }) {
    try {
      const { status, data } = await this.$axios.post(`/api/v1/requests/section/import?requestId=${requestId}`, section)
      if (status === 200) {
        return data
      } else {
        commit('application/snack/set', { type: 'error', message: 'Unable to import section. Please try again soon, or contact support.' }, { root: true })
        return null
      }
    } catch (e) {
      commit('application/snack/set', { type: 'error', message: 'Unable to import section. Please try again soon, or contact support.' }, { root: true })
      return null
    }
  },
  async updateSection({ commit }, card) {
    try {
      const result = await this.$axios.$post('/api/v1/requests/section', card)
      // update card in state
      commit('updateSection', result)
    } catch (e) {}
  },
  async deleteSection({ commit }, card) {
    const { status } = await this.$axios.delete(`/api/v1/requests/section/${card.id}`)
    if (status == 200) {
      commit('removeSection', card)
      commit('application/snack/set', { type: 'info', message: `Deleted ${card.name}` }, { root: true })
    }
  },
  async statusEvent({ commit, dispatch, state }, stompMessage) {
    if (!stompMessage) return
    const id = _.get(stompMessage, 'ids[0]')
    if (!id) return

    switch (stompMessage.eventName) {
      case 'opened':
        commit('updateField', { id: id, field: 'status', value: 'OPENED' })
        this.$bus.$emit('request-status', { id: id, status: 'OPENED' }) // Emit event so if the request is opened it is still updated
        break
      case 'complete':
        // refetch the file
        // commit('updateField', { id: id, field: 'status', value: 'COMPLETED' })
        //Need to grab the object, this dispatch wont update because its in
        // dispatch('getById', id)
        await dispatch('forceFetch', id)
        // commit('application/storeUtils/modifyCollection', { collection: state.allItems, item: await this.$axios.$get(`/api/v1/requests/${id}`), action: 'update' }, { root: true })
        this.$bus.$emit('request-completed', id) // Emit event so if the request is opened it is still updated
        break
    }
  },
  async downloadFile({ state, commit, dispatch }, path) {
    commit('application/loading/setLoading', true, { root: true })
    await this.$axios.get('/api/v1/requests/file/download', { params: { path }, responseType: 'blob' }).then((res) => {
      if (res.data) {
        commit('setToDownload', res)
      }
    })
    commit('application/loading/setLoading', false, { root: true })
  },
  async getFileBlob({ state, commit, dispatch }, path) {
    return (await this.$axios.get('/api/v1/requests/file/download', { params: { path }, responseType: 'blob' })).data
  },
  async updateRequestFile({ commit, getters }, { payload, id }) {
    commit('application/loading/setLoading', true, { root: true })

    return await this.$axios
      .put(`/api/v1/requests/` + id + `/file`, payload, { 'Content-Type': 'multipart/form-data;' })
      .then((res) => {
        commit('application/loading/setLoading', false, { root: true })
        return res
      })
      .catch((err) => {
        commit('application/loading/setLoading', false, { root: true })
        console.warn(err)
      })
  },
  async updateForm({ commit, getters }, { payload, id, callback, data }) {
    let rollbackRef = _.cloneDeep(Array.isArray(getters.getItems) ? getters.getItems.find((item) => item.id === id) : getters.getItems)
    if (_.isNil(rollbackRef) && Array.isArray(getters.getAllItems)) {
      rollbackRef = _.cloneDeep(getters.getAllItems.find((item) => item.id === id))
    }
    commit('application/loading/setLoading', true, { root: true })

    return await this.$axios
      .put(`/api/v1/requests/` + id, payload, { 'Content-Type': 'multipart/form-data;' })
      .then((res) => {
        commit('application/loading/setLoading', false, { root: true })
        if (callback) data !== null ? callback(res.status, data) : callback(res.status)
        commit('updateObject', res?.data)
        return res
      })
      .catch((err) => {
        commit('application/loading/setLoading', false, { root: true })
        if (callback) callback(null)
        console.warn(err)
      })
  },
}
