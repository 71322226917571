
export default {
  computed: {
    shouldShowHistory() {
      const blacklist = ['configurationHub', 'dashboard', 'fileManager']
      if (_.some(blacklist, (partialUrl) => this.$route.name.includes(partialUrl))) return false

      const hasIdQueryOrParam = _.has(this.$route.params, 'id') || this._.has(this.$route.query, 'id')
      if (hasIdQueryOrParam) return false

      return true
    },
    deleteHistory() {
      return _.defaultTo(this.$store.state.application?.snack?.history, []).filter(el => el?.data && el?.callback)
    },
    message() {
      let msg = this._.get(this.snack, 'message')
      return Array.isArray(msg) ? msg : [msg]
    },
    height() {
      // Manually set the height (20px per line of text + 20px padding) because the icon col doesn't want to fill height on its own
      let height = this._.get(this.snack, 'height')
      if (!height) height = (Array.isArray(this.message) ? this.message.length * 20 + 20 : 40) + 'px'
      return height
    },
    icon() {
      if (this._.get(this.snack, 'type')) {
        switch (this.snack.type) {
          case 'info':
            return 'information'
          case 'error':
            return 'warning-triangle'
          case 'success':
            return 'circle-check'
        }
      }
    },
    backgroundColour() {
      if (this._.get(this.snack, 'type')) {
        switch (this.snack.type) {
          case 'info':
            return 'var(--v-info-base)'
          case 'error':
            return 'var(--v-error-base)'
          case 'success':
            return 'var(--v-success-base)'
        }
      }
    },
    snack: {
      get: function () {
        return this.$store.state.application.snack.notification
      },
      set: function (val) {
        if (val === false) {
          this.$store.commit('application/snack/reset')
        }
      },
    },
  },
  methods: {
    async restoreFromHistory(historyItem) {
      const items = _.defaultTo([historyItem?.data], []).map(el => ({ data: el, action: `modules/${this.$utils.storeFromId(el.id)}/restore` }))
      const promises = []
      const groupedByEndpoint = _.groupBy(items, 'action')
      Object.entries(groupedByEndpoint).forEach(([action, items]) => {
        promises.push(this.$store.dispatch(action, items.map(item => item.data)))
      })

      await Promise.allSettled(promises)

      // remove the historyItem from snack store
      this.$store.commit('application/snack/removeHistoryItem', historyItem.id)
    },
    undo() {
      if (this.snack) {
        if (this.snack.timeoutId) clearTimeout(this.snack.timeoutId)
        if (this.snack.callback) this.snack.data ? this.snack.callback(this.snack.data) : this.snack.callback()
        else if (this.snack.module && this.snack.data && this.snack.action) {
          this.$store.dispatch('modules/' + this.snack.module + '/restore', this.snack.data)
          this.$store.commit('application/storeUtils/modifyCollection', { collection: this.$store.state.modules[this.snack.module].org ? this.$store.state.modules[this.snack.module].items : this.$store.getters[`modules/${this.snack.module}/getItems`], item: this.snack.data, action: this.snack.action })
        }
        this.snack = false
      }
    },
    openLink() {
      if (!_.get(this.snack, 'link')) return
      this.$router.push(_.get(this.snack, 'link'))
      this.snack = false
    },
    getModule(historyItem) {
      const id = _.get(historyItem, 'data.id', '')
      return this.$utils.capitaliseWords(this.$utils.storeFromId(id))
    }
  },
}
